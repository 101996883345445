import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  ActivityType,
  Country,
  Customer,
  Dealer,
  DealerImport,
  ECSCompany,
  Person,
  PersonAvailability,
  PersonStatus,
  Team,
} from "../../models/Core";
import { ISectionStatus } from "../../models/ISectionStatus";
import { IAutocompleteOption } from "../../models/UI";
import { Geocoding } from "../../models/ViewModels";
import {
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";
import { isNullOrUndefinedOrWhitespace } from "../../utils/generalHelpers";
import { RootState } from "../store";

export interface ICoreSliceState {
  personAvailabilityForProfile: PersonAvailability;
  persons: Person[];
  teams: Team[];
  customers: Customer[];
  subCustomers: Customer[];
  countries: Country[];
  dealers: Dealer[];
  ECSCompanies: ECSCompany[];
  activityTypes: ActivityType[];
  personStatuses: PersonStatus[];
  dealerImports: DealerImport[];

  sectionStatuses: {
    personAvailabilityForProfile: ISectionStatus;
    persons: ISectionStatus;
    teams: ISectionStatus;
    customers: ISectionStatus;
    subCustomers: ISectionStatus;
    countries: ISectionStatus;
    dealers: ISectionStatus;
    ECSCompanies: ISectionStatus;
    activityTypes: ISectionStatus;
    personStatuses: ISectionStatus;
    dealerImports: ISectionStatus;
  };
}

const initialState: ICoreSliceState = {
  personAvailabilityForProfile: {} as PersonAvailability,
  persons: [],
  teams: [],
  customers: [],
  subCustomers: [],
  countries: [],
  dealers: [],
  ECSCompanies: [],
  activityTypes: [],
  personStatuses: [],
  dealerImports: [],
  sectionStatuses: {
    personAvailabilityForProfile: { status: "idle", error: undefined },
    persons: { status: "idle", error: undefined },
    teams: { status: "idle", error: undefined },
    customers: { status: "idle", error: undefined },
    subCustomers: { status: "idle", error: undefined },
    countries: { status: "idle", error: undefined },
    dealers: { status: "idle", error: undefined },
    ECSCompanies: { status: "idle", error: undefined },
    activityTypes: { status: "idle", error: undefined },
    personStatuses: { status: "idle", error: undefined },
    dealerImports: { status: "idle", error: undefined },
  },
};

export const getActivityTypes = AsyncThunkFactoryGet<ActivityType[]>(
  "core/getActivityTypes",
  "activityTypes"
);

export const getDealerImportChanges = AsyncThunkFactoryGet<DealerImport[]>(
  "core/getDealerImportChanges",
  "DealerImports"
);

export const putDealerImportChanges = AsyncThunkFactoryPut<number[]>(
  "core/putDealerImportChanges",
  "DealerImports",
  "Successfully imported dealer changes",
  "Failed to import dealer changes"
);

export const putDealerImportGeocoding = AsyncThunkFactoryPut<Geocoding>(
  "core/putDealerImportGeocoding",
  "DealerImports/location",
  "Successfully updated dealer geocoding",
  "Failed to update dealer geocoding"
);

export const getPersonAvailabilityForProfile =
  AsyncThunkFactoryGet<PersonAvailability>(
    "core/getPersonAvailabilityForProfile",
    "Person"
  );

export const getPersons = AsyncThunkFactoryGet<Person[]>(
  "core/getPersons",
  "Person"
);

export const getTeams = AsyncThunkFactoryGet<Team[]>(
  "core/getTeams",
  "person/allTeams"
);

export const getECSCompanies = AsyncThunkFactoryGet<ECSCompany[]>(
  "core/getECSCompanies",
  "ECSCompanies"
);

export const getCustomers = AsyncThunkFactoryGet<Customer[]>(
  "core/getCustomers",
  "Customers"
);

export const getSubCustomers = AsyncThunkFactoryGet<Customer[]>(
  "core/getSubCustomers",
  "Customers"
);

export const getProjectCustomers = AsyncThunkFactoryGet<Customer[]>(
  "core/getProjectCustomers",
  "customers/project"
);

export const getCustomerDealers = AsyncThunkFactoryGet<Dealer[]>(
  "core/getCustomerDealers",
  "dealers/customer"
);

export const putCustomer = AsyncThunkFactoryPut<Customer>(
  "core/putCustomers",
  "Customers",
  "Successfully updated customer",
  "Failed to update customer"
);

export const postCustomer = AsyncThunkFactoryPost<Customer>(
  "core/postCustomers",
  "Customers",
  "Successfully added customer",
  "Failed to add customer"
);

export const getCountries = AsyncThunkFactoryGet<Country[]>(
  "core/getCountry",
  "Countries"
);

export const putCountry = AsyncThunkFactoryPut<Country>(
  "core/putCountry",
  "Countries",
  "Successfully updated country",
  "Failed to update country"
);

export const postCountry = AsyncThunkFactoryPost<Country>(
  "core/postCountry",
  "Countries",
  "Successfully added country",
  "Failed to add country"
);

export const getPersonStatusList = AsyncThunkFactoryGet<PersonStatus[]>(
  "core/getPersonStatusList",
  "person/statuslist"
);

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<ICoreSliceState>,
  f: any,
  field: keyof ICoreSliceState,
  statusField: keyof ICoreSliceState["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<ICoreSliceState>,
  f: any,
  field: keyof ICoreSliceState,
  statusField: keyof ICoreSliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

// function ActionReducerBuilderDelete<T>(
//   builder: ActionReducerMapBuilder<ICoreSliceState>,
//   f: any,
//   field: keyof ICoreSliceState,
//   statusField: keyof ICoreSliceState["sectionStatuses"],
//   predicate: (element: T) => boolean
// ) {
//   builder
//     .addCase(f.pending, (state) => {
//       state.sectionStatuses[statusField].status = "loading";
//     })
//     .addCase(f.fulfilled, (state, action) => {
//       state.sectionStatuses[statusField].status = "succeeded";
//       if (action.payload.status === 204) {
//         (state[field] as any[]) = (state[field] as any[]).filter(
//           predicate,
//           action.meta.arg
//         );
//       }
//     })
//     .addCase(f.rejected, (state, action) => {
//       state.sectionStatuses[statusField].status = "error";
//       state.sectionStatuses[statusField].error = action.error.message;
//     });
// }

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<ICoreSliceState>,
  f: any,
  field: keyof ICoreSliceState,
  statusField: keyof ICoreSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const coreSlice = createSlice({
  name: "core",
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "persons":
          state.sectionStatuses.persons.status = "idle";
          break;
        case "customers":
          state.sectionStatuses.customers.status = "idle";
          break;
        case "subCustomers":
          state.sectionStatuses.subCustomers.status = "idle";
          break;
        case "countries":
          state.sectionStatuses.countries.status = "idle";
          break;
        case "companies":
          state.sectionStatuses.ECSCompanies.status = "idle";
          break;
        case "activityType":
          state.sectionStatuses.activityTypes.status = "idle";
          break;
        case "personStatuses":
          state.sectionStatuses.personStatuses.status = "idle";
          break;
        case "dealerImports":
          state.sectionStatuses.dealerImports.status = "idle";
          break;
      }
    },
    resetCore: (state) => {
      state.sectionStatuses.persons.status = "idle";
      state.sectionStatuses.customers.status = "idle";
      state.sectionStatuses.subCustomers.status = "idle";
      state.sectionStatuses.countries.status = "idle";
      state.sectionStatuses.ECSCompanies.status = "idle";
      state.sectionStatuses.activityTypes.status = "idle";
      state.sectionStatuses.dealerImports.status = "idle";
    },
  },
  extraReducers: (builder) => {
    // activity types
    ActionReducerBuilderGet(
      builder,
      getActivityTypes,
      "activityTypes",
      "activityTypes"
    );

    ActionReducerBuilderGet(
      builder,
      getDealerImportChanges,
      "dealerImports",
      "dealerImports"
    );

    ActionReducerBuilderPut(
      builder,
      putDealerImportChanges,
      "dealerImports",
      "dealerImports"
    );

    ActionReducerBuilderPut(
      builder,
      putDealerImportGeocoding,
      "dealerImports",
      "dealerImports"
    );

    //dealers
    ActionReducerBuilderGet(builder, getCustomerDealers, "dealers", "dealers");

    ActionReducerBuilderGet(
      builder,
      getPersonAvailabilityForProfile,
      "personAvailabilityForProfile",
      "personAvailabilityForProfile"
    );

    ActionReducerBuilderGet(builder, getPersons, "persons", "persons");

    ActionReducerBuilderGet(builder, getTeams, "teams", "teams");

    ActionReducerBuilderGet(
      builder,
      getECSCompanies,
      "ECSCompanies",
      "ECSCompanies"
    );

    ActionReducerBuilderGet(builder, getCustomers, "customers", "customers");

    ActionReducerBuilderPut(builder, putCustomer, "customers", "customers");

    ActionReducerBuilderPost(
      builder,
      postCustomer,
      "customers",
      "customers",
      true
    );

    ActionReducerBuilderGet(
      builder,
      getSubCustomers,
      "subCustomers",
      "subCustomers"
    );

    ActionReducerBuilderGet(
      builder,
      getProjectCustomers,
      "subCustomers",
      "subCustomers"
    );

    ActionReducerBuilderGet(builder, getCountries, "countries", "countries");

    ActionReducerBuilderPut(builder, putCountry, "countries", "countries");

    ActionReducerBuilderPost(
      builder,
      postCountry,
      "countries",
      "countries",
      true
    );

    ActionReducerBuilderGet(
      builder,
      getPersonStatusList,
      "personStatuses",
      "personStatuses"
    );
  },
});

export const { resetStatus, resetCore } = coreSlice.actions;

export interface ICoreActivityTypeAsDict extends IAutocompleteOption {
  meta: {
    isProductive: boolean;
    isActive: boolean;
  };
}

export interface ICoreCustomerAsDict extends IAutocompleteOption {
  meta: {
    isParent: boolean;
    parentId: number | undefined | null;
  };
}

export interface ICoreDealersAsDict extends IAutocompleteOption {
  meta: {
    lat: number | undefined;
    long: number | undefined;
  };
}

export const coreActivityTypesAsDict = (
  state: RootState
): ICoreActivityTypeAsDict[] =>
  state.core.activityTypes
    .map((x) => ({
      value: x.id,
      label: x.name,
      meta: {
        isProductive: x.isProductive,
        isActive: x.isActive,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreNonDealerActivityTypesAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.core.activityTypes
    .filter((x) => !x.requiresDealer)
    .map((x) => ({ value: x.id, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const corePersonsAsDict = (state: RootState): IAutocompleteOption[] =>
  state.core.persons
    .map((x) => ({ value: x.personId, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreLivePersonsAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.core.persons
    .filter((x) => x.isLive)
    .map((x) => ({ value: x.personId, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreECSCompaniesAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.core.ECSCompanies.map((x) => ({
    value: x.ecsCompanyId,
    label: x.name,
  })).sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreCustomersAsDict = (state: RootState): ICoreCustomerAsDict[] =>
  state.core.customers
    .filter((x) => !isNullOrUndefinedOrWhitespace(x.name))
    .map((x) => ({
      value: x.id,
      label: `${x.name}${x.parentCustomerId ? "" : " (Parent)"}`,
      meta: {
        isParent: x.parentCustomerId === null,
        parentId: x.parentCustomerId,
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreECSCustomersAsDictOrderedByIsParent = (
  state: RootState
): ICoreCustomerAsDict[] =>
  state.core.customers
    .filter((x) => (x.id === 1 || x.parentCustomerId === 1) && x.isLive)
    .map((x) => ({
      value: x.id,
      label: x.name,
      meta: {
        isParent: x.parentCustomerId === null,
        parentId: x.parentCustomerId,
      },
    }))
    .sort((a, b) =>
      (a.meta.isParent ? "Customer" : "Sub Customer").localeCompare(
        b.meta.isParent ? "Customer" : "Sub Customer"
      )
    );

export const coreECSPlatformCustomersAsDictOrderedByIsParent = (
  state: RootState
): ICoreCustomerAsDict[] =>
  state.core.customers
    .filter(
      (x) =>
        (x.id === 1 ||
          x.parentCustomerId === 1 ||
          x.id === 5 ||
          x.parentCustomerId === 5) &&
        x.isLive
    )
    .map((x) => ({
      value: x.id,
      label: x.name,
      meta: {
        isParent: x.parentCustomerId === null,
        parentId: x.parentCustomerId,
      },
    }))
    .sort((a, b) =>
      (a.meta.isParent ? "Customer" : "Sub Customer").localeCompare(
        b.meta.isParent ? "Customer" : "Sub Customer"
      )
    );

export const coreCustomersAsDictOrderedByIsParent = (
  state: RootState
): ICoreCustomerAsDict[] =>
  state.core.customers
    .map((x) => ({
      value: x.id,
      label: x.name,
      meta: {
        isParent: x.parentCustomerId === null,
        parentId: x.parentCustomerId,
      },
    }))
    .sort((a, b) =>
      (a.meta.isParent ? "Customer" : "Sub Customer").localeCompare(
        b.meta.isParent ? "Customer" : "Sub Customer"
      )
    );

export const coreCustomersOrderedByIsParent = (state: RootState): Customer[] =>
  [...state.core.customers]?.sort((a, b) =>
    (a.parentCustomerId === null ? "Customer" : "Sub Customer").localeCompare(
      b.parentCustomerId === null ? "Customer" : "Sub Customer"
    )
  );

export const coreSubCustomersAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.core.subCustomers
    .map((x) => ({ value: x.id, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreDealersAsDict = (state: RootState): ICoreDealersAsDict[] =>
  state.core.dealers
    .map((x) => ({
      value: x.id,
      label: x.fullName,
      meta: { lat: x.latitude, long: x.longitude },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreCountriesAsDict = (state: RootState): IAutocompleteOption[] =>
  state.core.countries
    .map((x) => ({ value: x.id, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const coreTeamsAsDict = (state: RootState): IAutocompleteOption[] =>
  state.core.teams
    .map((x) => ({ value: x.teamId, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export default coreSlice.reducer;
