import { GridCellEditCommitParams } from "@mui/x-data-grid";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { QuotationStatus } from "../../models/Finance";
import { ISectionStatus } from "../../models/ISectionStatus";
import { StatusFlag } from "../../models/IStatusFlag";
import { PlanningAllocation } from "../../models/Planning";
import {
  Project,
  ProjectDeliveryPeriod,
  ProjectNote,
  ProjectPurchaseOrderNumber,
  ProjectStage,
  ProjectStatus,
  ProjectType,
} from "../../models/Project";
import { IAutocompleteOption } from "../../models/UI";
import { ComparePlanningAllocations } from "../../utils/DataGridHelpers";
import {
  AsyncThunkFactoryDelete,
  AsyncThunkFactoryGet,
  AsyncThunkFactoryPost,
  AsyncThunkFactoryPut,
} from "../../utils/NetworkUtils";
import { isNullOrUndefined } from "../../utils/generalHelpers";
import { RootState } from "../store";
import { IPlanningProjectsAsDict } from "./planning";

export interface IProjectSliceState {
  projects: Project[];
  project: Project;
  types: ProjectType[];
  statuses: ProjectStatus[];
  stages: ProjectStage[];
  planningAllocations: PlanningAllocation[];
  projectPurchaseOrderNumber: ProjectPurchaseOrderNumber;
  sectionStatuses: {
    projects: ISectionStatus;
    project: ISectionStatus;
    types: ISectionStatus;
    statuses: ISectionStatus;
    stages: ISectionStatus;
    planningAllocations: ISectionStatus;
    projectPurchaseOrderNumber: ISectionStatus;
  };
}

const initialState: IProjectSliceState = {
  projects: [],
  project: {} as Project,
  types: [],
  statuses: [],
  stages: [],
  planningAllocations: [],
  projectPurchaseOrderNumber: {} as ProjectPurchaseOrderNumber,
  sectionStatuses: {
    projects: { status: "idle", error: undefined },
    project: { status: "idle", error: undefined },
    types: { status: "idle", error: undefined },
    statuses: { status: "idle", error: undefined },
    stages: { status: "idle", error: undefined },
    planningAllocations: { status: "idle", error: undefined },
    projectPurchaseOrderNumber: { status: "idle", error: undefined },
  },
};

export const getProjects = AsyncThunkFactoryGet<Project[]>(
  "project/getProjects",
  "projects"
);

export const getProjectById = AsyncThunkFactoryGet<Project>(
  "project/getProjectById",
  "projects"
);

export const getProjectLimitedById = AsyncThunkFactoryGet<Project>(
  "project/getLimitedProjectById",
  "projects"
);

export const postProject = AsyncThunkFactoryPost<Partial<Project>>(
  "project/postProject",
  "projects",
  "Successfully added project",
  "Failed to add project"
);

export const putProject = AsyncThunkFactoryPut(
  "project/putProject",
  "projects",
  "Successfully updated project",
  "Failed to update project"
);

export const deleteProject = AsyncThunkFactoryDelete<Project>(
  "project/deleteProject",
  "projects",
  "Successfully deleted project",
  "Failed to delete project"
);

export const getTypes = AsyncThunkFactoryGet<ProjectType[]>(
  "project/getTypes",
  "projecttypes"
);

export const putType = AsyncThunkFactoryPut(
  "project/putTypes",
  "projecttypes",
  "Successfully updated project type",
  "Failed to update project type"
);

export const postType = AsyncThunkFactoryPost<ProjectType>(
  "project/postType",
  "projecttypes",
  "Successfully added project type",
  "Failed to add project type"
);

export const getStages = AsyncThunkFactoryGet<ProjectStage[]>(
  "project/getStages",
  "projectstages"
);

export const putStage = AsyncThunkFactoryPut(
  "project/putStages",
  "projectstages",
  "Successfully updated project stage",
  "Failed to update project stage"
);

export const postStage = AsyncThunkFactoryPost<ProjectStage>(
  "project/postStage",
  "projectstages",
  "Successfully added project stage",
  "Failed to add project stage"
);

export const getStatuses = AsyncThunkFactoryGet<ProjectStatus[]>(
  "project/getStatuses",
  "projectStatus"
);

export const putStatus = AsyncThunkFactoryPut(
  "project/putStatus",
  "projectstatus",
  "Successfully updated project status",
  "Failed to update project status"
);

export const postStatus = AsyncThunkFactoryPost<ProjectStatus>(
  "project/postStatus",
  "projectstatus",
  "Successfully added project status",
  "Failed to add project status"
);

export const postNote = AsyncThunkFactoryPost<Partial<ProjectNote>>(
  "project/postNote",
  "projectnotes",
  "Successfully added project note",
  "Failed to add project note"
);

export const getProjectPlanningAllocations = AsyncThunkFactoryGet<
  PlanningAllocation[]
>("project/planningAllocations", "planning/planningAllocations/project");

export const postProjectPurchaseOrderNumber = AsyncThunkFactoryPost<
  Partial<ProjectPurchaseOrderNumber>
>(
  "project/postProjectPurchaseOrder",
  "ProjectPurchaseOrderNumbers",
  "Successfully added Purchase Order Number",
  "Failed to add Purchase Order Number"
);

export const putProjectPurchaseOrderNumber = AsyncThunkFactoryPut(
  "project/putProjectPurchaseOrder",
  "ProjectPurchaseOrderNumbers",
  "Successfully updated Purchase Order Number",
  "Failed to update Purchase Order Number"
);

export const deleteProjectPurchaseOrderNumber =
  AsyncThunkFactoryDelete<ProjectPurchaseOrderNumber>(
    "project/deleteProjectPurchaseOrder",
    "ProjectPurchaseOrderNumbers",
    "Successfully deleted Purchase Order Number",
    "Failed to delete Purchase Order Number"
  );

function ActionReducerBuilderGet<T>(
  builder: ActionReducerMapBuilder<IProjectSliceState>,
  f: any,
  field: keyof IProjectSliceState,
  statusField: keyof IProjectSliceState["sectionStatuses"],
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      state[field] =
        manipulateResult !== undefined
          ? manipulateResult(action.payload.result)
          : action.payload.result;
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPost<T>(
  builder: ActionReducerMapBuilder<IProjectSliceState>,
  f: any,
  field: keyof IProjectSliceState,
  statusField: keyof IProjectSliceState["sectionStatuses"],
  shouldAppendResult: boolean,
  manipulateResult?: (result: T) => any
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (
        action.payload.status === 201 &&
        action.payload.result &&
        shouldAppendResult
      ) {
        var manipulated =
          manipulateResult !== undefined
            ? manipulateResult(action.payload.result)
            : action.payload.result;

        if (Array.isArray(state[field])) {
          (state[field] as any) = (state[field] as any).concat(manipulated);
        }
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderDelete<T>(
  builder: ActionReducerMapBuilder<IProjectSliceState>,
  f: any,
  field: keyof IProjectSliceState,
  statusField: keyof IProjectSliceState["sectionStatuses"],
  predicate: (element: T) => boolean,
  ignoreFilter?: boolean
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
      if (action.payload.status === 204 && !ignoreFilter) {
        (state[field] as any[]) = (state[field] as any[]).filter(
          predicate,
          action.meta.arg
        );
      }
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

function ActionReducerBuilderPut(
  builder: ActionReducerMapBuilder<IProjectSliceState>,
  f: any,
  field: keyof IProjectSliceState,
  statusField: keyof IProjectSliceState["sectionStatuses"]
) {
  builder
    .addCase(f.pending, (state) => {
      state.sectionStatuses[statusField].status = "loading";
    })
    .addCase(f.fulfilled, (state, action) => {
      state.sectionStatuses[statusField].status = "succeeded";
    })
    .addCase(f.rejected, (state, action) => {
      state.sectionStatuses[statusField].status = "error";
      state.sectionStatuses[statusField].error = action.error.message;
    });
}

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    addPlanningAllocation: (state, action: { payload: string }) => {
      var findLowest = Math.min(...state.planningAllocations.map((x) => x.id));
      state.planningAllocations = [
        {
          id: findLowest > 0 ? -1 : findLowest - 1,
          activityTypeId: 0,
          customerId: 0,
          personId: 0,
          projectId: action.payload,
          statusFlag: StatusFlag.New,
          suggestedMonth: 1,
          suggestedYear: new Date().getFullYear(),
          quantity: 0,
          duration: 0,
        },
        ...state.planningAllocations,
      ];
    },
    editPlanningAllocation: (
      state,
      action: {
        payload: {
          params: GridCellEditCommitParams;
          originalItem: PlanningAllocation | undefined;
        };
      }
    ) => {
      const { params, originalItem } = action.payload;
      var row = state.planningAllocations.find((x) => x.id === params.id);
      if (row === undefined) return;
      switch (params.field) {
        case "activityTypeId":
          row.activityTypeId = Number(params.value);
          break;
        case "customerId":
          row.customerId = Number(params.value);
          break;
        case "duration":
          row.duration = Number(params.value);
          break;
        case "personId":
          row.personId = Number(params.value);
          break;
        case "suggestedMonth":
          row.suggestedMonth = Number(params.value);
          break;
        case "suggestedYear":
          row.suggestedYear = Number(params.value);
          break;
        case "quantity":
          row.quantity = Number(params.value);
          break;
      }

      if (row !== undefined && !isNullOrUndefined(originalItem)) {
        if (ComparePlanningAllocations(row, originalItem))
          row.statusFlag = StatusFlag.Existing;
        else row.statusFlag = StatusFlag.Modified;
      }

      state.planningAllocations = state.planningAllocations.reduce(
        (acc: PlanningAllocation[], curr) => {
          if (curr.id !== params.id) acc.push(curr);
          else acc.push({ ...curr, ...row });
          return acc;
        },
        []
      );
    },
    markPlanningAllocationForDelete: (state, action: { payload: number }) => {
      state.planningAllocations = state.planningAllocations.reduce(
        (acc: PlanningAllocation[], curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else if (action.payload > 0)
            acc.push({ ...curr, statusFlag: StatusFlag.ToRemove });
          return acc;
        },
        []
      );
    },
    markPlanningAllocationForKeep: (
      state,
      action: {
        payload: { id: number; current: PlanningAllocation | undefined };
      }
    ) => {
      const { id, current } = action.payload;
      state.planningAllocations = state.planningAllocations.reduce(
        (acc: PlanningAllocation[], curr) => {
          if (curr.id !== id) acc.push(curr);
          else
            acc.push({
              ...curr,
              statusFlag: ComparePlanningAllocations(curr, current)
                ? StatusFlag.Existing
                : StatusFlag.Modified,
            });
          return acc;
        },
        []
      );
    },
    duplicatePlanningAllocation: (state, action: { payload: number }) => {
      var findLowest = Math.min(...state.planningAllocations.map((x) => x.id));
      var duplicatee = state.planningAllocations.find(
        (x) => x.id === action.payload
      );
      if (duplicatee === undefined) return;

      state.planningAllocations = state.planningAllocations.reduce(
        (acc: PlanningAllocation[], curr) => {
          if (curr.id !== action.payload) acc.push(curr);
          else {
            acc.push({
              ...curr,
              statusFlag: StatusFlag.New,
              id: findLowest > 0 ? -1 : findLowest - 1,
            });
            acc.push(curr);
          }
          return acc;
        },
        []
      );
    },
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "projects":
          state.sectionStatuses.projects.status = "idle";
          break;
        case "project":
          state.sectionStatuses.project.status = "idle";
          break;
        case "types":
          state.sectionStatuses.types.status = "idle";
          break;
        case "stages":
          state.sectionStatuses.stages.status = "idle";
          break;
        case "statuses":
          state.sectionStatuses.statuses.status = "idle";
          break;
      }
    },
    resetProject: (state) => {
      state.sectionStatuses.projects.status = "idle";

      state.sectionStatuses.project.status = "idle";

      state.sectionStatuses.types.status = "idle";

      state.sectionStatuses.stages.status = "idle";

      state.sectionStatuses.statuses.status = "idle";
    },
    clearPlanningAllocations: (state) => {
      state.planningAllocations = [];
    },
  },
  extraReducers: (builder) => {
    // Projects
    ActionReducerBuilderGet(builder, getProjects, "projects", "projects");

    ActionReducerBuilderGet(builder, getProjectById, "project", "project");

    ActionReducerBuilderGet(
      builder,
      getProjectLimitedById,
      "project",
      "project"
    );

    ActionReducerBuilderPost(builder, postProject, "project", "project", false);

    ActionReducerBuilderPut(builder, putProject, "project", "project");

    ActionReducerBuilderDelete(
      builder,
      deleteProject,
      "project",
      "project",
      function compareProjects(this: Project, element: Project) {
        return this.id === element.id;
      },
      true
    );

    ActionReducerBuilderGet(builder, getTypes, "types", "types");

    ActionReducerBuilderPut(builder, putType, "types", "types");

    ActionReducerBuilderPost(builder, postType, "types", "types", true);

    ActionReducerBuilderGet(builder, getStages, "stages", "stages");

    ActionReducerBuilderPut(builder, putStage, "stages", "stages");

    ActionReducerBuilderPost(builder, postStage, "stages", "stages", true);

    ActionReducerBuilderGet(builder, getStatuses, "statuses", "statuses");

    ActionReducerBuilderPut(builder, putStatus, "statuses", "statuses");

    ActionReducerBuilderPost(builder, postStatus, "statuses", "statuses", true);

    ActionReducerBuilderPost(builder, postNote, "project", "project", false);

    ActionReducerBuilderGet(
      builder,
      getProjectPlanningAllocations,
      "planningAllocations",
      "planningAllocations"
    );

    ActionReducerBuilderPost(
      builder,
      postProjectPurchaseOrderNumber,
      "projectPurchaseOrderNumber",
      "projectPurchaseOrderNumber",
      false
    );
    ActionReducerBuilderPut(
      builder,
      putProjectPurchaseOrderNumber,
      "projectPurchaseOrderNumber",
      "projectPurchaseOrderNumber"
    );
    ActionReducerBuilderDelete(
      builder,
      deleteProjectPurchaseOrderNumber,
      "projectPurchaseOrderNumber",
      "projectPurchaseOrderNumber",
      function compareProjectPurchaseOrderNumbers(
        this: ProjectPurchaseOrderNumber,
        element: ProjectPurchaseOrderNumber
      ) {
        return (
          this.purchaseOrderNumber === element.purchaseOrderNumber &&
          this.projectId === element.projectId
        );
      },
      true
    );
  },
});

export const {
  addPlanningAllocation,
  editPlanningAllocation,
  markPlanningAllocationForDelete,
  markPlanningAllocationForKeep,
  duplicatePlanningAllocation,
  resetStatus,
  resetProject,
  clearPlanningAllocations,
} = projectSlice.actions;

export const typesAsDict = (state: RootState): IAutocompleteOption[] =>
  state.projects.types
    .map((x) => ({ value: x.id, label: x.name }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const stagesAsDict = (state: RootState): IAutocompleteOption[] =>
  state.projects.stages
    .map((x) => ({ value: x.id, label: x.name, meta: x.order }))
    .sort((a, b) => a.meta - b.meta);

export const statusAsDict = (state: RootState): IAutocompleteOption[] =>
  state.projects.statuses
    .map((x) => ({ value: x.id, label: x.name }))
    .sort((a, b) => a.value - b.value);

export const projectsAsDict = (state: RootState): IPlanningProjectsAsDict[] =>
  state.projects.projects
    .map((x) => ({
      value: x.id,
      label: x.code + " " + x.title,
      meta: {
        hasFinalQuote: x.quotations?.some(
          (x) => x.quotationStatus === QuotationStatus.Final
        ),
      },
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const projectDeliveryAsDict = (): IAutocompleteOption[] =>
  Object.keys(ProjectDeliveryPeriod)
    .filter((v) => isNaN(Number(v)))
    .map((title) => ({
      value: ProjectDeliveryPeriod[title as keyof typeof ProjectDeliveryPeriod],
      label: title,
    }))
    .sort((a, b) => a?.label?.localeCompare(b?.label));

export const finalQuotation = (state: RootState) =>
  state.projects.project.quotations?.find((x) => x.quotationStatus === 2);

export const planningAllocationsOrderedByStatus = (state: RootState) =>
  state.projects.planningAllocations?.sort(
    (a, b) => a.statusFlag - b.statusFlag
  );

export const projectsECSProjectsAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.projects.projects
    .filter((x) => x.customerId === 1 || x.customer?.parentCustomerId === 1)
    .map((x) => ({ value: x.id, label: x.title }))
    .sort((a, b) => b?.label?.localeCompare(a?.label));

export const projectsECSPlatformProjectsAsDict = (
  state: RootState
): IAutocompleteOption[] =>
  state.projects.projects
    .filter(
      (x) =>
        x.customerId === 1 ||
        x.customer?.parentCustomerId === 1 ||
        x.customerId === 5 ||
        x.customer?.parentCustomerId === 5
    )
    .map((x) => ({ value: x.id, label: x.title }))
    .sort((a, b) => b?.label?.localeCompare(a?.label));

export default projectSlice.reducer;
