import { useMsal } from "@azure/msal-react";
import { ArrowUpwardRounded } from "@mui/icons-material";
import { Fab as MuiFab, Paper as MuiPaper, useMediaQuery } from "@mui/material";
import { spacing } from "@mui/system";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import BaseDialog from "../components/BaseDialog";
import GlobalStyle from "../components/GlobalStyle";
import FeedbackForm from "../components/feedback/FeedbackForm";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import { MSGraphPersonContext } from "../contexts/MSGraphPeopleContext";
import useAppDispatch from "../hooks/useAppDispatch";
import { IMSGraphPersonIndentifierType } from "../models/IMSGraph";
import {
  getFavouriteProjects,
  getNotifications,
  getRestrictedPersonnel,
  getSelf,
} from "../redux/slices/ui";
import dashboardItems from "../sidebarLinks/dashboardItems";
import { isNullOrUndefined } from "../utils/generalHelpers";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
   {
    width: ${(props) => (props.mobileOpen ? props.drawerWidth : 0)}px;
    flex-shrink: 0;
    height: 100%;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  flexgrow: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }

  padding-top: 1.5em;
`;

const Fab = styled(MuiFab)`
  background: ${(props) => props.theme.palette.primary.main};
  &:hover {
    background: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ArrowUpward = styled(ArrowUpwardRounded)`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const Dashboard = ({ children }) => {
  const mobile = !useMediaQuery("(min-width:600px)", { noSsr: true });
  const [mobileOpen, setMobileOpen] = useState(mobile ? false : true);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { accounts } = useMsal();
  const { requestPerson } = useContext(MSGraphPersonContext);
  const { pathname } = useLocation();
  const [psRef, setPsRef] = useState();

  const account = useMemo(() => accounts[0]?.homeAccountId ?? "", [accounts]);
  const accountDetails = useMemo(() => accounts[0]?.name, [accounts]);

  const getSelfCallback = useCallback(async () => {
    requestPerson(accountDetails, IMSGraphPersonIndentifierType.Name);

    dispatch(getRestrictedPersonnel());
    dispatch(getSelf()).then((res) => {
      if (!isNullOrUndefined(res?.payload?.result)) {
        dispatch(getNotifications());
        dispatch(getFavouriteProjects(res?.payload.result.personId));
      }
    });
  }, [dispatch, requestPerson, accountDetails]);

  useEffect(() => {
    getSelfCallback();
  }, [account, getSelfCallback]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToTop = useCallback(() => {
    if (!isNullOrUndefined(psRef)) {
      psRef.scrollTop = 0;
      psRef.scrollLeft = 0;
    }
  }, [psRef]);

  useEffect(() => {
    scrollToTop();
  }, [psRef, pathname, scrollToTop]);

  return (
    <>
      <ReactPerfectScrollbar containerRef={(ref) => setPsRef(ref)}>
        <Root>
          <GlobalStyle />
          <Drawer mobileOpen={mobileOpen} drawerWidth={drawerWidth}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="persistent"
              hideBackdrop
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
            {/* <Hidden mdDown implementation="css">
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                items={dashboardItems}

              />
            </Hidden> */}
          </Drawer>
          <AppContent>
            <Navbar
              onDrawerToggle={handleDrawerToggle}
              openFeedback={() => setFeedbackDialogOpen((x) => !x)}
            />
            <MainContent p={3} id="mainContent">
              {children}
              <Outlet />
            </MainContent>
          </AppContent>
        </Root>
      </ReactPerfectScrollbar>
      <Fab
        size="medium"
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
        onClick={() => scrollToTop()}
      >
        <ArrowUpward />
      </Fab>
      <BaseDialog
        isOpen={feedbackDialogOpen}
        handleClose={() => setFeedbackDialogOpen((x) => !x)}
        hideCloseButton
        fullWidth
        maxWidth={"lg"}
        children={
          <FeedbackForm handleClose={() => setFeedbackDialogOpen((x) => !x)} />
        }
      />
    </>
  );
};

export default Dashboard;
