import { Customer, ECSCompany, Log, Person } from "./Core";
import { Quotation } from "./Finance";
import { PlanningAllocation } from "./Planning";
import { ActivitySchedule } from "./Scheduling";

export interface Project {
  id: string;
  code: string;
  title: string;

  parentProjectId: string;
  parentProject?: Project;

  projectTypeId: number;
  projectType?: ProjectType;

  startDate: Date;
  endDate: Date;
  deliveryPeriod: ProjectDeliveryPeriod;

  projectStageId: number;
  projectStage?: ProjectStage;

  projectStatusId: number;
  projectStatus?: ProjectStatus;

  // Project Coordinator
  personId: number | null;
  person?: Person;

  customerId: number;
  customer?: Customer;

  ecsCompanyId: number | null;
  ecsCompany?: ECSCompany;

  lastModifiedDate: Date | string;
  lastModifiedBy: number;
  lastModifiedPerson?: Person;

  // Metadata
  isRenewal: boolean;
  isRandD: boolean;
  randDDateSubmitted?: Date;
  // purchaseOrderNumber: string | null;
  externalProjectLink: string | null;

  quotations?: Quotation[];
  activitySchedules?: ActivitySchedule[];

  purchaseOrderNumbers?: ProjectPurchaseOrderNumber[];
  currentPurchaseOrderNumber?: ProjectPurchaseOrderNumber;

  planningAllocations?: PlanningAllocation[];
  projectCustomers?: ProjectCustomer[];
  projectCustomerCount?: number;
  projectMilestones?: ProjectMilestone[];
  projectNotes?: ProjectNote[];
  log?: Log[];

  tense: string | null;
  tenseOrder: number;
}

export function NewProject() {
  return {} as Project;
}

export interface ProjectStage {
  id: number;
  name: string;
  order: number;
}

export interface ProjectStatus {
  id: number;
  name: string;
}

export interface ProjectType {
  id: number;
  name: string;
}

export interface ProjectLog {
  id: string;
  projectId: string;
  personId: number;
  person?: Person;
  message: string;
  logDate: Date;
}

export interface ProjectMilestone {
  id: string;
  title: string;
  projectId: string;
  personId: number;
  person?: Person;
  dueDate?: Date;
  isComplete: boolean;
  dateCompleted?: Date;
}

export interface ProjectNote {
  id: string;
  projectId: string;
  text: string;
  dateAdded: Date;
  personId: number;
  person?: Person;
}

export interface ProjectCustomer {
  projectId: string;
  project?: Project;
  customerId: number;
  customer?: Customer;
}

export interface ProjectPurchaseOrderNumber {
  projectId: string;
  purchaseOrderNumber: string;
  isCurrent: boolean;
  startDate: Date;
  endDate: Date;
  title: string;
}

export enum ProjectDeliveryPeriod {
  FY = 1,
  CY = 2,
}
